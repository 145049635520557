import React, { Component } from "react";
import Header from "./includes/Header";
import Sidebar from "./includes/Sidebar";
import URLs from "./utils/apis";
import axios from "axios";
import { Spin, message } from "antd";

export default class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      loading: false,
      details_loader: false,
      password_loader: false,

      old_password: "",
      new_password: "",
      confirm_password: "",
    };
  }
  componentDidMount() {
    this.get_data();
  }
  get_data = () => {
    this.setState({ loading: true });
    let params = {
      id: localStorage.getItem("super_admin_id"),
    };
    axios
      .post(URLs.get_super_admin_details, params)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            name: response.data.data[0].name,
            email: response.data.data[0].email,
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
      });
  };
  update_password = () => {
    if (!this.state.old_password) {
      message.warning("Old Password is required.");
      return;
    }
    if (!this.state.new_password) {
      message.warning("New Password is required.");
      return;
    }
    if (!this.state.confirm_password) {
      message.warning("Confirm Password is required.");
      return;
    }
    if (this.state.new_password != this.state.confirm_password) {
      message.warning(
        "Both New Password and Confirm Password is not matching."
      );
      return;
    }
    let params = {
      id: localStorage.getItem("super_admin_id"),
      new_password: this.state.new_password,
      old_password: this.state.old_password,
      confirm_password: this.state.confirm_password,
    };
    this.setState({ password_loader: true });
    axios
      .post(URLs.update_password, params)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState({
            password_loader: false,
            old_password: "",
            new_password: "",
            confirm_password: "",
          });
        } else {
          message.success(response.data.message);
          this.setState({ password_loader: false });
        }
      })
      .catch((error) => {
        this.setState({ password_loader: false });
        console.error("Warning:", error);
      });
  };
  update_details = () => {
    if (!this.state.name) {
      message.warning("Name is required.");
      return;
    }
    if (!this.state.email) {
      message.warning("Email address is required.");
      return;
    }
    let params = {
      id: localStorage.getItem("super_admin_id"),
      email: this.state.email,
      name: this.state.name,
    };
    this.setState({ details_loader: true });
    axios
      .post(URLs.update_details, params)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState({ details_loader: false });
        } else {
          message.success(response.data.message);
          this.setState({ details_loader: false });
        }
      })
      .catch((error) => {
        this.setState({ details_loader: false });
        console.error("Warning:", error);
      });
  };
  render() {
    return (
      <div>
        <div className="container-scroller">
          <Header />
          <div className="container-fluid page-body-wrapper">
            {/* <!-- partial:partials/_settings-panel.html --> */}
            <Sidebar />
            {/* <!-- partial --> */}
            <div className="main-panel">
              <div className="content-wrapper">
                <div className="row">
                  <div className="col-md-12 grid-margin">
                    <div className="d-flex justify-content-between flex-wrap">
                      <div className="d-flex align-items-center dashboard-header flex-wrap mb-3 mb-sm-0">
                        <h5
                          className="mr-4 mb-0 font-weight-bold"
                          style={{ fontSize: 24 }}
                        >
                          Settings
                        </h5>
                        <div className="d-flex align-items-baseline dashboard-breadcrumb"></div>
                      </div>
                      <div className="d-flex"></div>
                    </div>
                  </div>
                  <div class="col-md-6 grid-margin stretch-card">
                    <div class="card">
                      <div class="card-body">
                        <Spin spinning={this.state.details_loader}>
                          <form class="forms-sample">
                            <div class="form-group">
                              <label for="exampleInputEmail1">Name</label>
                              <input
                                type="text"
                                class="form-control"
                                id="exampleInputEmail1"
                                placeholder="Name"
                                value={this.state.name}
                                onChange={(e) =>
                                  this.setState({ name: e.target.value })
                                }
                              />
                            </div>
                            <div class="form-group">
                              <label for="exampleInputEmail1">
                                Email address
                              </label>
                              <input
                                type="text"
                                class="form-control"
                                id="exampleInputEmail1"
                                placeholder="Email"
                                value={this.state.email}
                                onChange={(e) =>
                                  this.setState({ email: e.target.value })
                                }
                              />
                            </div>
                            <button
                              type="button"
                              onClick={() => this.update_details()}
                              class="btn btn-primary mr-2"
                            >
                              Update
                            </button>
                          </form>
                        </Spin>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 grid-margin stretch-card">
                    <div class="card">
                      <div class="card-body">
                        <Spin spinning={this.state.password_loader}>
                          <form class="forms-sample">
                            <div class="form-group">
                              <label for="exampleInputPassword1">
                                Old Password
                              </label>
                              <input
                                type="password"
                                class="form-control"
                                id="exampleInputPassword1"
                                placeholder="New Password"
                                value={this.state.old_password}
                                onChange={(e) =>
                                  this.setState({
                                    old_password: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div class="form-group">
                              <label for="exampleInputPassword1">
                                New Password
                              </label>
                              <input
                                type="password"
                                class="form-control"
                                id="exampleInputPassword1"
                                placeholder="New Password"
                                value={this.state.new_password}
                                onChange={(e) =>
                                  this.setState({
                                    new_password: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div class="form-group">
                              <label for="exampleInputConfirmPassword1">
                                Confirm Password
                              </label>
                              <input
                                type="password"
                                class="form-control"
                                id="exampleInputConfirmPassword1"
                                placeholder="Password"
                                value={this.state.confirm_password}
                                onChange={(e) =>
                                  this.setState({
                                    confirm_password: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <button
                              type="button"
                              onClick={() => this.update_password()}
                              class="btn btn-primary mr-2"
                            >
                              Change Password
                            </button>
                          </form>
                        </Spin>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- content-w/rapper ends --> */}
              {/* <!-- partial:partials/_footer.html --> */}
              <footer className="footer">
                <div className="d-sm-flex justify-content-center justify-content-sm-between">
                  <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
                    Copyright © 2024 . All rights reserved.
                  </span>
                </div>
              </footer>
              {/* <!-- partial --> */}
            </div>
            {/* <!-- main-panel ends --> */}
          </div>
          {/* <!-- page-body-wrapper ends --> */}
        </div>
      </div>
    );
  }
}
