import React, { Component } from "react";
import Header from "./includes/Header";
import Sidebar from "./includes/Sidebar";
import URLs from "./utils/apis";
import axios from "axios";

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      admin_users: 0,
      loading: false,
    };
  }
  componentDidMount() {
    this.get_data();
  }
  get_data = () => {
    this.setState({ loading: true });
    axios
      .get(URLs.get_dashboard_data)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            admin_users: response.data.data,
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
      });
  };
  render() {
    return (
      <div>
        <div className="container-scroller">
          <Header />
          <div className="container-fluid page-body-wrapper">
            {/* <!-- partial:partials/_settings-panel.html --> */}
            <Sidebar />
            {/* <!-- partial --> */}
            <div className="main-panel">
              <div className="content-wrapper">
                <div className="row">
                  <div className="col-md-12 grid-margin">
                    <div className="d-flex justify-content-between flex-wrap">
                      <div className="d-flex align-items-center dashboard-header flex-wrap mb-3 mb-sm-0">
                        <h5 className="mr-4 mb-0 font-weight-bold">
                          Dashboard
                        </h5>
                        <div className="d-flex align-items-baseline dashboard-breadcrumb">
                          {/* <i className="mdi mdi-chevron-right mr-1 text-muted"></i> */}
                          {/* <box-icon
                            name="chevron-right"
                            color="#919191"
                          ></box-icon>
                          <p className="text-muted mb-0 mr-1 hover-cursor">
                            Dashboard
                          </p> */}
                          {/* <i className="mdi mdi-chevron-right mr-1 text-muted"></i> */}
                          {/* <p className="text-muted mb-0 hover-cursor">
                            Analytics
                          </p> */}
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="btn-group mr-3">
                          {/* <button type="button" className="btn btn-primary">
                            02 Aug 2019
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary dropdown-toggle dropdown-toggle-split"
                            id="dropdownMenuSplitButton1"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <span className="sr-only">Toggle Dropdown</span>
                          </button>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuSplitButton1"
                          >
                            <a className="dropdown-item" href="#">
                              Sept 2019
                            </a>
                            <a className="dropdown-item" href="#">
                              Oct 2019
                            </a>
                            <a className="dropdown-item" href="#">
                              Nov 2019
                            </a>
                          </div> */}
                        </div>
                        {/* <button className="btn bg-white border d-none d-sm-block">
                          Download Report
                        </button> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-6 col-md-3 grid-margin stretch-card">
                    <div className="card icon-card-primary">
                      <div className="card-body">
                        <div className="d-flex align-items-center">
                          <div className="icon mb-0 mb-md-2 mb-xl-0 mr-2">
                            <box-icon
                              name="user-circle"
                              color="#fff"
                              size="20px"
                              style={{
                                position: "relative",
                                top: 5,
                                left: 5,
                              }}
                            ></box-icon>
                          </div>
                          <p className="font-weight-medium mb-0">Admin Users</p>
                        </div>
                        <div className="d-flex align-items-center mt-3 flex-wrap">
                          <h3 className="font-weight-normal mb-0 mr-2">
                            {this.state.admin_users}
                          </h3>
                          <div className="badge badge-outline-light badge-pill mt-md-2 mt-xl-0"></div>
                        </div>
                        <small className="font-weight-medium d-block mt-2">
                          All Time Total
                        </small>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-12 col-sm-6 col-md-3 grid-margin stretch-card">
                    <div className="card icon-card-success">
                      <div className="card-body">
                        <div className="d-flex align-items-center">
                          <div className="icon mb-0 mb-md-2 mb-xl-0 mr-2">
                            <i className="mdi mdi-truck"></i>
                          </div>
                          <p className="font-weight-medium mb-0">Users</p>
                        </div>
                        <div className="d-flex align-items-center mt-3 flex-wrap">
                          <h3 className="font-weight-normal mb-0 mr-2">21</h3>
                          <div className="badge badge-outline-light badge-pill mt-md-2 mt-xl-0"></div>
                        </div>
                        <small className="font-weight-medium d-block mt-2">
                          All Time Total
                        </small>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="col-12 col-sm-6 col-md-3 grid-margin stretch-card">
                    <div className="card icon-card-info">
                      <div className="card-body">
                        <div className="d-flex align-items-center">
                          <div className="icon mb-0 mb-md-2 mb-xl-0 mr-2">
                            <i className="mdi mdi-basket"></i>
                          </div>
                          <p className="font-weight-medium mb-0">Purchases</p>
                        </div>
                        <div className="d-flex align-items-center mt-3 flex-wrap">
                          <h3 className="font-weight-normal mb-0 mr-2">
                            39645
                          </h3>
                          <div className="badge badge-outline-light badge-pill mt-md-2 mt-xl-0">
                            <div className="d-flex align-items-baseline">
                              <span className="mr-2">Low</span>
                              <span className="mb-0">0.21</span>
                              <span className="mdi mdi-arrow-down"></span>
                            </div>
                          </div>
                        </div>
                        <small className="font-weight-medium d-block mt-2">
                          Total for this month
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-3 grid-margin stretch-card">
                    <div className="card icon-card-dark">
                      <div className="card-body">
                        <div className="d-flex align-items-center">
                          <div className="icon mb-0 mb-md-2 mb-xl-0 mr-2">
                            <i className="mdi mdi-package-down"></i>
                          </div>
                          <p className="font-weight-medium mb-0">Downloads</p>
                        </div>
                        <div className="d-flex align-items-center mt-3 flex-wrap">
                          <h3 className="font-weight-normal mb-0 mr-2">
                            44148
                          </h3>
                          <div className="badge badge-outline-light badge-pill mt-md-2 mt-xl-0">
                            <div className="d-flex align-items-baseline">
                              <span className="mr-2">Low</span>
                              <span className="mb-0">1.12</span>
                              <span className="mdi mdi-arrow-down"></span>
                            </div>
                          </div>
                        </div>
                        <small className="font-weight-medium d-block mt-2">
                          Total for this month
                        </small>
                      </div>
                    </div>
                  </div> */}
                </div>
                {/*
                <div className="row">
                  <div className="col-md-4 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex align-items-start justify-content-between">
                          <p className="card-title">Customers</p>
                          <div className="dropdown dropright card-menu-dropdown">
                            <button
                              className="btn"
                              type="button"
                              id="cardMenuButton1"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i className="mdi mdi-dots-horizontal card-menu-btn"></i>
                            </button>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="cardMenuButton1"
                            >
                              <a className="dropdown-item" href="#">
                                Action
                              </a>
                              <a className="dropdown-item" href="#">
                                Another action
                              </a>
                              <a className="dropdown-item" href="#">
                                Something else here
                              </a>
                            </div>
                          </div>
                        </div>
                        <p className="text-muted mb-5">
                          Total customers this month
                        </p>
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <h1 className="font-weight-bold">92556</h1>
                          <div className="d-flex align-items-baseline flex-wrap justify-content-center">
                            <h5 className="mb-0 font-weight-normal text-success">
                              1.35
                            </h5>
                            <i className="mdi mdi-arrow-up text-success mr-1"></i>
                            <h5 className="mb-0 mt-0 mt-md-2 mt-xl-0 font-weight-normal">
                              More than last month
                            </h5>
                          </div>
                        </div>
                      </div>
                      <canvas height="110" id="customers-chart"></canvas>
                    </div>
                  </div>
                  <div className="col-md-4 grid-margin stretch-card">
                    <div className="card overflow-hidden">
                      <div className="card-body">
                        <div className="d-flex align-items-start justify-content-between">
                          <p className="card-title">Conversion</p>
                          <div className="dropdown dropleft card-menu-dropdown">
                            <button
                              className="btn"
                              type="button"
                              id="cardMenuButton2"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i className="mdi mdi-dots-horizontal card-menu-btn"></i>
                            </button>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="cardMenuButton2"
                            >
                              <a className="dropdown-item" href="#">
                                Action
                              </a>
                              <a className="dropdown-item" href="#">
                                Another action
                              </a>
                              <a className="dropdown-item" href="#">
                                Something else here
                              </a>
                            </div>
                          </div>
                        </div>
                        <p className="text-muted mb-5">
                          Total conversion this month
                        </p>
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <h1 className="font-weight-bold">53812</h1>
                          <div className="d-flex align-items-baseline flex-wrap justify-content-center">
                            <h5 className="mb-0 font-weight-normal text-danger">
                              0.17
                            </h5>
                            <i className="mdi mdi-arrow-down text-danger mr-1"></i>
                            <h5 className="mb-0 mt-0 mt-md-2 mt-xl-0 font-weight-normal">
                              Less than last month
                            </h5>
                          </div>
                        </div>
                      </div>
                      <canvas height="110" id="conversion-chart"></canvas>
                    </div>
                  </div>
                  <div className="col-md-4 grid-margin stretch-card">
                    <div className="card overflow-hidden">
                      <div className="card-body">
                        <div className="d-flex align-items-start justify-content-between">
                          <p className="card-title">Revenue</p>
                          <div className="dropdown dropright card-menu-dropdown">
                            <button
                              className="btn"
                              type="button"
                              id="cardMenuButton3"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i className="mdi mdi-dots-horizontal card-menu-btn"></i>
                            </button>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="cardMenuButton3"
                            >
                              <a className="dropdown-item" href="#">
                                Action
                              </a>
                              <a className="dropdown-item" href="#">
                                Another action
                              </a>
                              <a className="dropdown-item" href="#">
                                Something else here
                              </a>
                            </div>
                          </div>
                        </div>
                        <p className="text-muted mb-5">
                          Total revenue this month
                        </p>
                        <div className="d-flex flex-column justify-content-center align-items-center">
                          <h1 className="font-weight-bold">40008</h1>
                          <div className="d-flex align-items-baseline flex-wrap justify-content-center">
                            <h5 className="mb-0 font-weight-normal text-success">
                              0.06
                            </h5>
                            <i className="mdi mdi-arrow-up text-success mr-1"></i>
                            <h5 className="mb-0 mt-0 mt-md-2 mt-xl-0 font-weight-normal">
                              Less than last month
                            </h5>
                          </div>
                        </div>
                      </div>
                      <canvas height="110" id="revenue-chart"></canvas>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-8 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex align-items-start justify-content-between">
                          <p className="card-title flex-grow">
                            Revenue for Last 30 days
                          </p>
                          <div className="d-flex align-items-center">
                            <div className="dropdown dropright card-menu-dropdown">
                              <button
                                className="btn"
                                type="button"
                                id="cardMenuButton4"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i className="mdi mdi-dots-horizontal card-menu-btn"></i>
                              </button>
                              <div
                                className="dropdown-menu"
                                aria-labelledby="cardMenuButton4"
                              >
                                <a className="dropdown-item" href="#">
                                  Action
                                </a>
                                <a className="dropdown-item" href="#">
                                  Another action
                                </a>
                                <a className="dropdown-item" href="#">
                                  Something else here
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-start flex-wrap ">
                          <p className="text-muted mb-0">
                            Sales Performance for Online revenue of past 30 days
                          </p>
                          <div
                            className="online-revenue-chart-legend mt-2 mt-sm-0 mt-md-2 mt-xl-0 mr-1"
                            id="online-revenue-legend"
                          ></div>
                        </div>
                        <canvas id="online-revenue-chart"></canvas>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex align-items-start justify-content-between">
                          <p className="card-title flex-grow">
                            all online sales
                          </p>
                          <div className="dropdown dropright card-menu-dropdown">
                            <button
                              className="btn"
                              type="button"
                              id="cardMenuButton5"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i className="mdi mdi-dots-horizontal card-menu-btn"></i>
                            </button>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="cardMenuButton5"
                            >
                              <a className="dropdown-item" href="#">
                                Action
                              </a>
                              <a className="dropdown-item" href="#">
                                Another action
                              </a>
                              <a className="dropdown-item" href="#">
                                Something else here
                              </a>
                            </div>
                          </div>
                        </div>
                        <p className="text-muted">
                          The total number of sessions within the date range. It
                          is the period time a user is actively engaged with
                          your website, page or app, etc.{" "}
                        </p>
                        <canvas
                          id="online-sales-chart"
                          className="mt-5 mt-md-4 mt-xl-5"
                        ></canvas>
                        <div className="d-flex d-md-none d-xl-flex justify-content-between mt-5 mx-2">
                          <div className="d-flex flex-column align-items-center">
                            <h3 className="font-weight-bold">12%</h3>
                            <small>Sales total</small>
                          </div>
                          <div className="d-flex flex-column align-items-center">
                            <h3 className="font-weight-bold">20%</h3>
                            <small>Sales total</small>
                          </div>
                          <div className="d-flex flex-column align-items-center">
                            <h3 className="font-weight-bold">67%</h3>
                            <small>Sales total</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-7 col-xl-8 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex align-items-start justify-content-between">
                          <p className="card-title flex-grow">
                            market overview
                          </p>
                          <div className="dropdown dropright card-menu-dropdown">
                            <button
                              className="btn"
                              type="button"
                              id="cardMenuButton6"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i className="mdi mdi-dots-horizontal card-menu-btn"></i>
                            </button>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="cardMenuButton6"
                            >
                              <a className="dropdown-item" href="#">
                                Action
                              </a>
                              <a className="dropdown-item" href="#">
                                Another action
                              </a>
                              <a className="dropdown-item" href="#">
                                Something else here
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th className="pl-0 pt-0">Product</th>
                                <th className="pt-0">Type</th>
                                <th className="pt-0">SKU</th>
                                <th className="pt-0">Quantity</th>
                                <th className="pt-0">Amount</th>
                                <th className="pr-0 pt-0">Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="pl-0">
                                  <div className="d-flex">
                                    <div className="icon-rounded-success mr-3">
                                      <i className="mdi mdi-check"></i>
                                    </div>
                                    <div>
                                      WMQ96921
                                      <p className="smaller-text mb-0 mt-2 text-muted">
                                        18 Sep 2019
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td>#ABU81275</td>
                                <td>931</td>
                                <td>8</td>
                                <td>14689</td>
                                <td className="pr-0">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <label className="badge badge-outline-success mr-4 mr-xl-2">
                                      Completed
                                    </label>
                                    <div className="icon-square-sm-danger hover-cursor">
                                      <i className="mdi mdi-delete"></i>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className="pl-0">
                                  <div className="d-flex">
                                    <div className="icon-rounded-danger mr-3">
                                      <i className="mdi mdi-close"></i>
                                    </div>
                                    <div>
                                      WMQ60538
                                      <p className="smaller-text mb-0 mt-2 text-muted">
                                        29 May 2019
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td>#HGA55521</td>
                                <td>828</td>
                                <td>37</td>
                                <td>19888</td>
                                <td className="pr-0">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <label className="badge badge-outline-danger mr-4 mr-xl-2">
                                      Cancelled
                                    </label>
                                    <div className="icon-square-sm-danger hover-cursor">
                                      <i className="mdi mdi-delete"></i>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className="pl-0">
                                  <div className="d-flex">
                                    <div className="icon-rounded-warning mr-3">
                                      <i className="mdi mdi-information-outline"></i>
                                    </div>
                                    <div>
                                      WMQ95994
                                      <p className="smaller-text mb-0 mt-2 text-muted">
                                        24 Jun 2019
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td>#HGA99169</td>
                                <td>340</td>
                                <td>36</td>
                                <td>90939</td>
                                <td className="pr-0">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <label className="badge badge-outline-warning mr-4 mr-xl-2">
                                      Delayed
                                    </label>
                                    <div className="icon-square-sm-danger hover-cursor">
                                      <i className="mdi mdi-delete"></i>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className="pl-0">
                                  <div className="d-flex">
                                    <div className="icon-rounded-warning mr-3">
                                      <i className="mdi mdi-information-outline"></i>
                                    </div>
                                    <div>
                                      WMQ37340
                                      <p className="smaller-text mb-0 mt-2 text-muted">
                                        14 Mar 2019
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td>#HGA26866</td>
                                <td>128</td>
                                <td>7</td>
                                <td>41198</td>
                                <td className="pr-0">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <label className="badge badge-outline-warning mr-4 mr-xl-2">
                                      Delayed
                                    </label>
                                    <div className="icon-square-sm-danger hover-cursor">
                                      <i className="mdi mdi-delete"></i>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className="pl-0">
                                  <div className="d-flex">
                                    <div className="icon-rounded-success mr-3">
                                      <i className="mdi mdi-check"></i>
                                    </div>
                                    <div>
                                      WMQ61947
                                      <p className="smaller-text mb-0 mt-2 text-muted">
                                        05 Apr 2019
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td>#XCA68589</td>
                                <td>643</td>
                                <td>67</td>
                                <td>68601</td>
                                <td className="pr-0">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <label className="badge badge-outline-success mr-4 mr-xl-2">
                                      Completed
                                    </label>
                                    <div className="icon-square-sm-danger hover-cursor">
                                      <i className="mdi mdi-delete"></i>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className="pl-0">
                                  <div className="d-flex">
                                    <div className="icon-rounded-success mr-3">
                                      <i className="mdi mdi-check"></i>
                                    </div>
                                    <div>
                                      WMQ37027
                                      <p className="smaller-text mb-0 mt-2 text-muted">
                                        07 Sep 2019
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td>#XCA24410</td>
                                <td>527</td>
                                <td>73</td>
                                <td>77178</td>
                                <td className="pr-0">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <label className="badge badge-outline-success mr-4 mr-xl-2">
                                      Completed
                                    </label>
                                    <div className="icon-square-sm-danger hover-cursor">
                                      <i className="mdi mdi-delete"></i>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="d-flex justify-content-between align-items-center flex-wrap">
                          <nav>
                            <ul className="pagination flex-wrap pagination-separated pagination-dark mt-3 mb-0">
                              <li className="page-item">
                                <a className="page-link" href="#">
                                  Prev
                                </a>
                              </li>
                              <li className="page-item">
                                <a className="page-link" href="#">
                                  1
                                </a>
                              </li>
                              <li className="page-item">
                                <a className="page-link" href="#">
                                  2
                                </a>
                              </li>
                              <li className="page-item">
                                <a className="page-link" href="#">
                                  Next
                                </a>
                              </li>
                            </ul>
                          </nav>
                          <p className="text-muted mt-3 mb-0 ml-3 ml-md-0 d-none d-md-block">
                            Showing 6 out of 100
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 col-xl-4 grid-margin stretch-card">
                    <div className="card overflow-hidden">
                      <div className="card-body">
                        <div className="d-flex align-items-start justify-content-between">
                          <p className="card-title flex-grow">sales total</p>
                          <div className="dropdown dropright card-menu-dropdown">
                            <button
                              className="btn"
                              type="button"
                              id="cardMenuButton7"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i className="mdi mdi-dots-horizontal card-menu-btn"></i>
                            </button>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="cardMenuButton7"
                            >
                              <a className="dropdown-item" href="#">
                                Action
                              </a>
                              <a className="dropdown-item" href="#">
                                Another action
                              </a>
                              <a className="dropdown-item" href="#">
                                Something else here
                              </a>
                            </div>
                          </div>
                        </div>
                        <h2 className="font-weight-bold mb-3">$971883</h2>
                        <p className="text-muted mb-4">
                          The French Revolution constituted for the conscience
                          of the dominant aristocratic class a fall from
                          innocence, and upturning of the natural chain
                        </p>
                        <div className="row">
                          <div className="col-md-4">
                            <h4 className="font-weight-bold">400</h4>
                            <div className="progress progress-md mb-2">
                              <div
                                className="progress-bar bg-primary"
                                role="progressbar"
                                style={{ width: "30%" }}
                                aria-valuenow="30"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                            <small>Sales</small>
                          </div>
                          <div className="col-md-4">
                            <h4 className="font-weight-bold mt-3 mt-md-0">
                              159
                            </h4>
                            <div className="progress progress-md mb-2">
                              <div
                                className="progress-bar bg-success"
                                role="progressbar"
                                style={{ width: "75%" }}
                                aria-valuenow="75"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                            <small>Orders</small>
                          </div>
                        </div>
                      </div>
                      <canvas id="total-sales-chart"></canvas>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-7 grid-margin grid-margin-md-0 stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex align-items-start justify-content-between">
                          <p className="card-title flex-grow">sales total</p>
                          <div className="dropdown dropright card-menu-dropdown">
                            <button
                              className="btn"
                              type="button"
                              id="cardMenuButton8"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i className="mdi mdi-dots-horizontal card-menu-btn"></i>
                            </button>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="cardMenuButton8"
                            >
                              <a className="dropdown-item" href="#">
                                Action
                              </a>
                              <a className="dropdown-item" href="#">
                                Another action
                              </a>
                              <a className="dropdown-item" href="#">
                                Something else here
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="table-responsive">
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th>Invoice number</th>
                                <th>Franchise</th>
                                <th>Date</th>
                                <th>Status</th>
                                <th>Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>00980814</td>
                                <td>West Paris</td>
                                <td>03 Oct 2019</td>
                                <td>
                                  <label className="badge badge-success">
                                    Completed
                                  </label>
                                </td>
                                <td>58976</td>
                              </tr>
                              <tr>
                                <td>00928130</td>
                                <td>Sincereberg</td>
                                <td>02 Jul 2019</td>
                                <td>
                                  <label className="badge badge-warning">
                                    Delayed
                                  </label>
                                </td>
                                <td>2379</td>
                              </tr>
                              <tr>
                                <td>00990867</td>
                                <td>New Otis</td>
                                <td>06 Sep 2019</td>
                                <td>
                                  <label className="badge badge-danger">
                                    Cancelled
                                  </label>
                                </td>
                                <td>69432</td>
                              </tr>
                              <tr>
                                <td>00983826</td>
                                <td>Delilahfurt</td>
                                <td>26 May 2019</td>
                                <td>
                                  <label className="badge badge-success">
                                    Completed
                                  </label>
                                </td>
                                <td>32354</td>
                              </tr>
                              <tr>
                                <td>0091820</td>
                                <td>North Darrel</td>
                                <td>11 Aug 2019</td>
                                <td>
                                  <label className="badge badge-success">
                                    Completed
                                  </label>
                                </td>
                                <td>14192</td>
                              </tr>
                              <tr>
                                <td>00997307</td>
                                <td>South Melissa</td>
                                <td>02 Jan 2019</td>
                                <td>
                                  <label className="badge badge-warning">
                                    Delayed
                                  </label>
                                </td>
                                <td>20680</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex align-items-start justify-content-between">
                          <p className="card-title flex-grow">
                            Purchases For The Year
                          </p>
                          <div className="dropdown dropright card-menu-dropdown">
                            <button
                              className="btn"
                              type="button"
                              id="cardMenuButton9"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i className="mdi mdi-dots-horizontal card-menu-btn"></i>
                            </button>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="cardMenuButton9"
                            >
                              <a className="dropdown-item" href="#">
                                Action
                              </a>
                              <a className="dropdown-item" href="#">
                                Another action
                              </a>
                              <a className="dropdown-item" href="#">
                                Something else here
                              </a>
                            </div>
                          </div>
                        </div>
                        <canvas id="purchases-chart" height="200"></canvas>
                        <div className="d-flex justify-content-center mt-1 mt-xl-3">
                          <small className="font-weight-bold">Purchases</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
              {/* <!-- content-w/rapper ends --> */}
              {/* <!-- partial:partials/_footer.html --> */}
              <footer className="footer">
                <div className="d-sm-flex justify-content-center justify-content-sm-between">
                  <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
                    Copyright © 2024 . All rights reserved.
                  </span>
                </div>
              </footer>
              {/* <!-- partial --> */}
            </div>
            {/* <!-- main-panel ends --> */}
          </div>
          {/* <!-- page-body-wrapper ends --> */}
        </div>
      </div>
    );
  }
}
