let BASE_URL = "https://sapi.affixtech.in/api/";
let URLs = {
  login: BASE_URL + "super-admin/login",
  get_dashboard_data: BASE_URL + "super-admin/get-dashboard-count",
  get_super_admin_details: BASE_URL + "super-admin/get_details",
  // admin
  get_admin_user_list: BASE_URL + "super-admin/admin_users/list",
  add_new_admin_user: BASE_URL + "super-admin/admin_users/add",
  update_admin_user_details: BASE_URL + "super-admin/admin_users/update",
  update_admin_user_status: BASE_URL + "super-admin/admin_users/update_status",

  update_details: BASE_URL + "super-admin/update_details",
  update_password: BASE_URL + "super-admin/update_password",

  get_district_list: BASE_URL + "super-admin/get_district_list",
};

export default URLs;
